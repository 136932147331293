import "./Footer.css";
import haze from '../../assets/images/haze.png';
import dappradar from '../../assets/images/dappradar.png';

export const Footer = () => {
  return (
    <div className="footer-container">
      <div>
        <p>Powered by</p>
        <a href="#">BSC Network</a>
      </div>
      <div>
        <div className="partners-wrap-ft">
          <div>
           
          </div>
          <div>
          
          </div>
        </div>
      </div>
      <div>
        <p>@ 2024 by xpdev. All rights reserved.</p>
        <a href="_blank">https://staking.apadaku.com</a>
      </div>
    </div>
  );
};

export default Footer;
