import { Deposit } from "../Deposit/Deposit";
import Earning from "../Earning/Earning";
import { StackingCard } from "../StackingCard/StackingCard";
import "../Staking-container/Staking.css";
import DateCountdown from 'react-date-countdown-timer';
import { Count } from "../Count/count";


export const Staking = () => {
  const plan = {
   
    "plan4": {
        'plan' : 0,
        'title' : "LOCKED Bronze",
        'daily' : "0.55%",
        'days' : '180 ',
        'roi' : "200%",
        'locked' : true
    },  
    "plan5": {
        'plan' : 1,
        'title' : "LOCKED Silver",
        'daily' : "0.61%",
        'days' : '270 ',
        'roi' : "220%",
        'locked' : true
    },   
    "plan6": {
        'plan' : 2,
        'title' : "LOCKED Gold",
        'daily' : "0.66%",
        'days' : '360 ',
        'roi' : "240%",
        'locked' : true
    },       
  };

  return (
    <>
      <div className="staking-plans-container">
        <h5 className="pannel-title">PACKAGES</h5>
        <div className="anytime-plans-wrap">
         
        </div>
        <div className="anytime-plans-wrap LOCKED-PLANS">
        <StackingCard  plan = {Object.values(plan)[0]}/>
          <StackingCard plan = {Object.values(plan)[1]}/>
          <StackingCard plan = {Object.values(plan)[2]}/>
        </div>
        <Earning />
        <Deposit />
      </div>
    </>
  );
};
export default Staking;
