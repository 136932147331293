import "../Home-container/Home-container.css";
import { BodyContainer } from "../Body-container/Body-container";
import Staking from "../Staking-container/Staking";


export const HomeContainer = (props) => {
  return <>
    <BodyContainer/>
    <Staking/>
  </>
};
export default HomeContainer;
