import { useState, useEffect } from "react";
import "../StackingCard/StackingCard.css";
import { useWalletStore } from "../store";
import { getTotalStaked, invest, getContractBalance, getWalletBalance } from "../../util/interact";
import ReactLoading from 'react-loading';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { useSearchParams } from "react-router-dom";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { contractAddress, chainId, rContractAddress} from "../../constants/address";
import rAbi from "../../constants/rContract.json";
import { ethers, BigNumber } from "ethers";
import Web3 from "web3";

export const StackingCard = (props) => {
  const { title, days, daily, locked, roi, plan } = props.plan;

  const web3 = new Web3(
    new Web3.providers.HttpProvider(
     "https://bsc-mainnet.public.blastapi.io/"
    )
  );

  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  const contract = new ethers.Contract(rContractAddress, rAbi, signer)

  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [buttonText, setButtonText] = useState("DEPOSIT");
  const [approvalTxHash, setApprovalTxHash] = useState(null);
  
  const currentWalletAddress = useWalletStore((state) => state.currentWalletAddress);
  let [searchParams] = useSearchParams();
  const referrer = searchParams.get("ref") || currentWalletAddress;

  const notstarted = useWalletStore((state) => state.notstarted);
  const updateTotalStaked = useWalletStore((state) => state.updateTotalStaked);
  const updateContractBalance = useWalletStore((state) => state.updatecontractBalance);
  const updateWalletBalance = useWalletStore((state) => state.updateWalletBalance);
  const updatestakedBnb = useWalletStore(
    (state) => state.updatestakedBnb
  );

  useEffect(() => {
    const fetchTotalStaked = async () => {
      const totalStakedAmount = await getTotalStaked();
      updateTotalStaked(totalStakedAmount);
    };

    fetchTotalStaked();
  }, [updateTotalStaked]);

  const investing = async () => {
    try {
      // Original amount
      const amount2 = parseFloat(amount); // Ensure amount is a number

      // Calculate the amount with an additional 6%
      const increasedAmount = amount2 * 1.06;
      const amountInWei = web3.utils.toWei(amount.toString(), 'ether');  

      if (currentWalletAddress) {
        const data = {
          address: currentWalletAddress,
          plan: plan,
          investAmount: amount,
          referrer: referrer
        };

        setLoading(true);
        setButtonText("APPROVING");
        
        try {
          const result = await invest(data);
          //window.location.reload();
        
          if (result && result.success) {
         
            //setApprovalTxHash(result.apvtx);
            //NotificationManager.success('Approval has been successful', 'Success');
            setButtonText("Stake Proccess");

            const gasEstimate = result.gasEstimate;
          
         // Perform the actual investment transaction
         const tx = await contract.invest(data.referrer, data.plan, amountInWei, {
          from: data.address,
          gasLimit: gasEstimate
        });
        const res = await tx.wait();

        if (res.transactionHash) {
          NotificationManager.success('Your investment has been successful', 'Success');
          console.log(`Transaction Hash: ${res.transactionHash}`, 'Transaction Info', 5000);

          setAmount(0);
          const totalStaked = await getTotalStaked();
          updateTotalStaked(totalStaked);
          
          const contractBalance = await getContractBalance();
          updateContractBalance(contractBalance);
          
          const walletBal = await getWalletBalance(currentWalletAddress);
          updateWalletBalance(walletBal);

          const stakedBnb = await updatestakedBnb();
          updatestakedBnb(stakedBnb);
        }else {
          NotificationManager.error('Investment failure', 'Error', 3000);
        }
            
            // Refresh the page
           
          } else {
            NotificationManager.error('Investment failure', 'Error', 3000);
          }
        } catch (error) {
          console.error('Approve/Invest function error:', error);
          NotificationManager.error('Investment failed due to an error', 'Error', 3000);
        }

        setLoading(false);
        setButtonText("DEPOSIT");
      }
    } catch (error) {
      console.error('Unexpected error in investing function:', error);
      NotificationManager.error('An unexpected error occurred', 'Error', 3000);
      setLoading(false);
      setButtonText("DEPOSIT");
    }
  };

  return (
    <>
      <BlockUi tag="div" blocking={loading}>
        <div className="plans-box-wrap">
          <div className="flex-div-wrap-header">
            <h3>{title}</h3>
            {locked ? (
              <img src="https://img.icons8.com/material/24/000000/lock--v1.png" alt="Locked"/>
            ) : (
              <img src="https://img.icons8.com/material-outlined/24/000000/open-lock.png" alt="Unlocked"/>
            )}
          </div>
          <div className="flex-div-wrap">
            <div>
              <p>Daily Earnings</p>
              <span style={{ color: 'black', fontWeight: "900" }}>{daily}</span>
            </div>
            <div>
              <p>Total ROI</p>
              <span style={{ color: 'black', fontWeight: "900" }}>{roi}</span>
            </div>
          </div>
          <div className="days-locked">
            <div>
              <h3 style={{ color: "#022A29" }}>{days}</h3>
              <p style={{ color: "#022A29" }}>DAYS</p>
            </div>
          </div>
          <div className="flex-div-wrap">
            <div>
              <p>Enter Amount APADA</p>
              <input
                type="number"
                value={amount}
                step={0.01}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
            <div>
              <p>ROI in {days} Days</p>
              <span>{(amount * parseFloat(roi) * 0.01).toFixed(3)}</span>
            </div>
          </div>
          <div className="stake-button-wrap">
            <button
              className="stake-button-wrap-btn"
              onClick={investing}
              disabled={notstarted}
            >
              {buttonText}
            </button>
          </div>
          {approvalTxHash && (
            <div className="approval-tx-hash">
              <p>Approval Transaction Hash: {approvalTxHash}</p>
            </div>
          )}
        </div>
      </BlockUi>
      <NotificationContainer/>
    </>
  );
};
