import "../Menubar/Menubar.css";

export const Menubar = () => {
  return (
    <div className="menu-container">
     
     
    </div>
  );
};

export default Menubar;
