import { ethers, BigNumber } from "ethers";
import Web3 from "web3";
import { contractAddress, chainId, rContractAddress} from "../constants/address";
import axios from "axios";
import abi from "../constants/contract.json";
import rAbi from "../constants/rContract.json";
var BN = require('bignumber.js');

const web3 = new Web3(
  new Web3.providers.HttpProvider(
    "https://bsc-mainnet.public.blastapi.io/"
  )
);

const contract = new web3.eth.Contract(abi, contractAddress);
const rContract = new web3.eth.Contract(rAbi, rContractAddress);

function parseUnit(unit) {
  var decimalsToUnit = {
    '-18': 'wei',
    '-15': 'kwei',
    '-12': 'mwei',
    '-9': 'gwei',
    '-6': 'szabo',
    '-3': 'finney',
    1: 'ether',
    3: 'kether',
    6: 'mether',
    9: 'gether',
    12: 'tether'
  };
  if (typeof unit === 'string') {
    unit = unit.trim().toLowerCase();
  }
  if (unit !== undefined && !isNaN(unit)) {
    unit = decimalsToUnit[unit];
  }
  return unit;
}

function convertToEther(value, unit) {
  if (value === undefined) {
    throw TypeError('value is required');
  }
  unit = parseUnit(unit);
  if (unit === undefined) {
    throw TypeError('unit is required');
  }

  var v = new BN(value);
  if (unit === 'eth') unit = 'ether';

  if (unit === 'wei') return v.times(new BN(0.000000000000000001));
  if (unit === 'kwei') return v.times(new BN(0.000000000000001));
  if (unit === 'mwei') return v.times(new BN(0.000000000001));
  if (unit === 'gwei') return v.times(new BN(0.000000001));
  if (unit === 'szabo') return v.times(new BN(0.000001));
  if (unit === 'finney') return v.times(new BN(0.001));
  if (unit === 'ether') return v.times(new BN(1));
  if (unit === 'kether') return v.times(new BN(1000));
  if (unit === 'mether') return v.times(new BN(1000000));
  if (unit === 'gether') return v.times(new BN(1000000000));
  if (unit === 'tether') return v.times(new BN(1000000000000));

  throw TypeError('Invalid unit');
}

function converter(value, unit, toUnit) {
  unit = parseUnit(unit);
  toUnit = parseUnit(toUnit);
  var v = convertToEther(value, unit);
  if (unit === 'eth') unit = 'ether';
  if (toUnit === 'eth') toUnit = 'ether';

  var result = {
    wei: null,
    kwei: null,
    mwei: null,
    gwei: null,
    szabo: null,
    finney: null,
    ether: null,
    kether: null,
    mether: null,
    gether: null,
    tether: null
  };

  result[unit] = new BN(value).toString(10);

  if (unit !== 'wei') {
    result.wei = v.times(new BN(1000000000000000000)).toString(10);
  }
  if (unit !== 'kwei') {
    result.kwei = v.times(new BN(1000000000000000)).toString(10);
  }
  if (unit !== 'mwei') {
    result.mwei = v.times(new BN(1000000000000)).toString(10);
  }
  if (unit !== 'gwei') {
    result.gwei = v.times(new BN(1000000000)).toString(10);
  }
  if (unit !== 'szabo') {
    result.szabo = v.times(new BN(1000000)).toString(10);
  }
  if (unit !== 'finney') {
    result.finney = v.times(new BN(1000)).toString(10);
  }
  if (unit !== 'ether') {
    result.ether = v.times(new BN(1)).toString(10);
  }
  if (unit !== 'kether') {
    result.kether = v.times(new BN(0.001)).toString(10);
  }
  if (unit !== 'mether') {
    result.mether = v.times(new BN(0.000001)).toString(10);
  }
  if (unit !== 'gether') {
    result.gether = v.times(new BN(0.000000001)).toString(10);
  }
  if (unit !== 'tether') {
    result.tether = v.times(new BN(0.000000000001)).toString(10);
  }

  if (toUnit) {
    if (result[toUnit] === undefined) {
      throw TypeError('Invalid unit');
    }

    return result[toUnit];
  }

  return result;
}

export const toWeiToDecimals = (value, decimals) => {
  const convertedValue = converter(value, "ether", -1 * decimals);
  console.log("value", value);
  console.log("convertedValue", convertedValue);
  return convertedValue?.toString();
};

export const getContract = () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  const contract = new ethers.Contract(contractAddress, abi, signer)
  return contract
}

export const getRcontract = () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  const contract = new ethers.Contract(rContractAddress, rAbi, signer)
  return contract
}


export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      const chain = await window.ethereum.request({
        method: "eth_chainId",
      });
      if (addressArray.length > 0 && chain === chainId) {
        return {
          address: addressArray[0],
          status: "Connected.",
        };
      } else {
        return {
          address: "",
          status:
            "Connect to Metamask and choose the correct chain using the top right button.",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            {/* <a target="_blank" href={`https://metamask.io/download.html`}> */}
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.(https://metamask.io/download.html)
            {/* </a> */}
          </p>
        </span>
      ),
    };
  }
};
const sendTransaction = async (data) => {
  try {
    const txHash = await window.ethereum.request({
      method: data.method,
      params: [data.params],
    });
    return {
      txHash : txHash,
      success: true,
      // status: (
      //   <span>
      //     <p>
      //       ✅ Please check your transaction on rinkeby.etherscan.io
      //       <br />
      //       <a
      //         target="_blank"
      //         href={`https://rinkeby.etherscan.io/tx/` + txHash}
      //         rel="noreferrer"
      //       >
      //         {"https://rinkeby.etherscan.io/tx/" + txHash}
      //       </a>
      //     </p>
      //   </span>
      // ),
    };
  } catch (error) {
    return {
      success: false,
      status: "Something went wrong: " + error.message,
    };
  }
};

// export const invest = async (data) => {
//   const res = await sendTransaction({
//     method: "eth_sendTransaction",
//     params: {
//       from: data.address,
//       to: contractAddress,
//       value: ethers.BigNumber.from(data.investAmount.toString())._hex,
//       data: contract.methods.invest(data.referrer, data.plan).encodeABI(),
//     },
//   })
//   console.log(res)
// };
function toWei(amount) {
  return ethers.utils.parseUnits(amount.toString(), 18);
}


export const approveToken = async (tokenAddress, spenderAddress, amount) => {
  try {
    const { ethereum } = window;
    if (!ethereum) {
      console.error("Metamask not detected");
      return false;
    }
    
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const tokenContract = new ethers.Contract(tokenAddress, abi, signer);
    // Convert the amount to the smallest unit
    const amountInWei = ethers.utils.parseUnits(amount.toString(), 18);
    console.log(`Amount in wei 1: ${amountInWei}`);
    const tx = await tokenContract.approve(spenderAddress, amount);
    let res = await tx.wait()
    if (res.transactionHash) {
      console.log(res.transactionHash)
      return {apvtx : res.transactionHash, success:true};
    }
    
  } catch (error) {
    console.error("Error approving tokens:", error);
    return false;
  }
};

/*
export const invest = async (data) => {
  try {
    let ContractOf = new web3.eth.Contract(rAbi, rContractAddress);
    const accounts = await web3.eth.getAccounts();
    let tx = await ContractOf.methods.invest(data.referrer, data.plan, data.investAmount.toString()).send({ from: accounts[0] });
    let res = await tx.wait()
    if (res.transactionHash) {
      console.log(res.transactionHash)
      console.log("Investment successful");
      
    }
    return true;
  } catch (error) {
    console.error("Error while investing:", error);
  }
}
*/

/*
export const invest = async (data) => {
  const contract = getRcontract();
  try {
    
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const stakingContract = new ethers.Contract(rContractAddress, rAbi, signer);
    const decimals = 18;


    let amountInWei = ethers.utils.parseEther(data.investAmount.toString());

    // Potong 10% dari jumlah investasi
    let discountedAmountInWei = amountInWei.mul(70).div(100);

    console.log("Jumlah invest:", discountedAmountInWei);

    const trans_amount = discountedAmountInWei;
    const amount =  discountedAmountInWei;//web3.utils.toWei(trans_amount.toString(), 'ether');  //ethers.parseEther(data.investAmount.toString());  // Replace with the actual amount

    console.log(`Amount in wei: ${trans_amount.toString()}-${data.referrer}-plan ${data.plan}`);

      // Check allowance first
      const tokenContract = new ethers.Contract(contractAddress, abi, signer); // tokenAddress should be the address of the ERC20 token
      const allowance = await tokenContract.allowance(data.address, rContractAddress);
  
      if (allowance.lt(amount)) {
        console.log('Approving tokens for investment...', 'Info');
  
        // Approve the token transfer
        const approved = await approveToken(contractAddress, rContractAddress, amount);
        if (!approved) {
          console.log('Token approval failed. Please try again.', 'Error', 3000);
          return;
        }
      }
    // Estimate gas for the transaction
    let gasEstimate;
    try {
      gasEstimate = await stakingContract.estimateGas.invest(data.referrer, data.plan, amount);
      console.log("Estimated gas:", gasEstimate.toString());
    } catch (error) {
      console.error("Gas estimation failed:", error);
      //NotificationManager.error('Gas estimation failed. Please try again.', 'Error', 3000);
      return;
    }



     let tx = await stakingContract.invest(data.referrer, data.plan, amount).send({ from: data.address });//invest(data.referrer, data.plan, { value: amount.toString(), from: data.address });
    
     let res = await tx.wait();
      if (res.transactionHash) {
        return {tx : res.transactionHash, success:true};
      }

    

  } catch (err) {
    console.log(err)
  }
} 

*/







// export const withdraw = async (walletAddress) => {
//   const res = await sendTransaction({
//     method: "eth_sendTransaction",
//     params: {
//       from: walletAddress,
//       to: contractAddress,
//       data: contract.methods.withdraw().encodeABI(),
//     },
//   });
//   return res;
// };


export const withdraw = async () => {
  const contract = getRcontract();
  try {
      let tx = await contract.withdraw();
      let res = await tx.wait()
      if (res.transactionHash) {
        return {tx : res.transactionHash, success:true};
      }
  } catch (err) {
    console.log(err)
  }
} 

export const invest = async (data) => {
  const contract = getRcontract();
  
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const tokenContract = new ethers.Contract(contractAddress, abi, signer);
    const allowance = await tokenContract.allowance(data.address, rContractAddress);
    const amount2 = parseFloat(data.investAmount); // Ensure amount is a number

      // Calculate the amount with an additional 6%
      const increasedAmount = amount2 * 1.10;

      
    const amount = Web3.utils.toWei(increasedAmount.toString(), 'ether');
    const amountgas = Web3.utils.toWei(data.investAmount.toString(), 'ether');
    
    let approvalTxHash;
    let approvalTxsukses;
    if (allowance.lt(amount)) {
      console.log('Approving tokens for investment...', 'Info');
      
      // Approve the token transfer
      const approvalResult = await approveToken(contractAddress, rContractAddress, amount);
      if (!approvalResult || !approvalResult.success) {
        console.log('Token approval failed. Please try again.', 'Error', 3000);
        return;
      }
      approvalTxHash = approvalResult.apvtx;
      approvalTxsukses = approvalResult.success;
    }else {
      console.log('Tokens have been Approving  for investment...', 'Info');
     
    }
    
    
   // Estimate gas
   const gasEstimate = await contract.estimateGas.invest(data.referrer, data.plan, amountgas);
   console.log("Estimated gas:", gasEstimate.toNumber());

   // Return the approval transaction hash and gas estimate
   return { apvtx: approvalTxHash, gasEstimate: gasEstimate.toNumber(),success: true };
    
      
   
   
    /*
    let tx = await contract.invest(data.referrer, data.plan, amount).send({ from: data.address });
    let res = await tx.wait();
    if (res.transactionHash) {
      console.log(res.transactionHash);
      return { tx: res.transactionHash, apvtx: approvalTxHash, success: true };
    }
    */
   
  } catch (err) {
    console.log(err);
    return { success: false };
  }
};

/*
export const invest = async (data) => {
 
  try {
    const contract = getRcontract();
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const stakingContract = new ethers.Contract(rContractAddress, rAbi, signer);
    const amount =  web3.utils.toWei(data.investAmount.toString(), 'ether');
    
    console.log("Cek DEPO:", amount);

      // Check allowance first
      const tokenContract = new ethers.Contract(contractAddress, abi, signer); // tokenAddress should be the address of the ERC20 token
      const allowance = await tokenContract.allowance(data.address, rContractAddress);
  
      if (allowance.lt(amount)) {
        console.log('Approving tokens for investment...', 'Info');
  
        // Approve the token transfer
        const approved = await approveToken(contractAddress, rContractAddress, amount);
        if (!approved) {
          console.log('Token approval failed. Please try again.', 'Error', 3000);
          return;
        }
      }
      
      let tx = await stakingContract.invest(data.referrer, data.plan, amount).send({ from: data.address });
      let res = await tx.wait()
      if (res.transactionHash) {
        return {tx : res.transactionHash, success:true};
      }
  } catch (err) {
    console.log(err)
  }
} 
*/

// Wallet address to check balance


// BEP-20 token contract address (example: BUSD token)


// BEP-20 token contract ABI (minimum ABI to interact with the balanceOf method)
const minABI = [
  // balanceOf
  {
    constant: true,
    inputs: [{ name: "name", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "", type: "uint256" }],
    type: "function"
  },
  // decimals
  {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [{ name: "name", type: "uint8" }],
    type: "function"
  }
];

// Create contract instance


export const getTokenBalance = async (walletAddress, tokenPepe) => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
    const tokenContract = new web3.eth.Contract(minABI, tokenPepe, signer);
    const balance = await tokenContract.methods.balanceOf(walletAddress).call();
    // Get the token decimals
    const decimals = await tokenContract.methods.decimals().call();

    // Adjust the balance based on the decimals
    const adjustedBalance = balance / (10 ** decimals);
    console.log("Cek SALDO TOKEN:", adjustedBalance);
    return parseFloat(adjustedBalance).toFixed(0);
  } catch (error) {
    console.error('An error occurred while fetching the raw balance:', error);
    throw error;
  }
};

export const getTotalStaked = async () => {
  const totalStaked = await rContract.methods.totalStaked().call();
  return totalStaked;
};

export const getContractBalance = async () => {
  const contractBalance = await rContract.methods.getContractBalance().call();
  return contractBalance;
};

export const getWalletBalance = async (walletAddress) => {
  var walletBalance = await web3.eth.getBalance(walletAddress);
  walletBalance = web3.utils.fromWei(walletBalance);
  return walletBalance;
};

export const getUserTotalDeposits = async (walletAddress) => {
  const stakedBnb = await rContract.methods
    .getUserTotalDeposits(walletAddress)
    .call();
  return stakedBnb;
};

export const getUserDividends = async (walletAddress) => {
  const stakedBnb = await rContract.methods
    .getUserDividends(walletAddress)
    .call();
  return stakedBnb;
};

export const getUserReferralBonus = async (walletAddress) => {
  const stakedBnb = await rContract.methods
    .getUserReferralBonus(walletAddress)
    .call();
  return stakedBnb;
};

export const getUserReferralTotalBonus = async (walletAddress) => {
  const stakedBnb = await rContract.methods
    .getUserReferralTotalBonus(walletAddress)
    .call();
  return stakedBnb;
};
export const getUserReferralWithdrawn = async (walletAddress) => {
  const stakedBnb = await rContract.methods
    .getUserReferralWithdrawn(walletAddress)
    .call();
  return stakedBnb;
};

export const blockStamp = async () => {
  const blockObj = await web3.eth.getBlock(await web3.eth.getBlockNumber());
  const curBlockTimestamp = blockObj.timestamp;
  return curBlockTimestamp;
}
