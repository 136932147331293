import "../Deposit/Deposit.css"

export const Deposit = () => {
    return(
        <>
         <h5 className="pannel-title">
           
          </h5>
          <div className="deposit-container" style={{marginBottom: '50px'}}></div>
        </>
    )
}